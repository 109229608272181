import axios from "@/axios";
import { Type } from "@/types";

/**
 * @param search {string|undefined} Часть названия типа для поиска
 */
export async function TypeListGet(
  search?: string | number
): Promise<Array<Type> | undefined> {
  const { data } = await axios.get<Array<Type>>("/type", {
    params: { search },
  });
  return data;
}
