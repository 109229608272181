import validate_role from "@/router/module/validate_role";
import get_title from "@/router/module/get_title";

export default {
  path: "/content/settings",
  meta: {
    title: get_title("Настройки"),
    forVisitors: true,
  },
  component: () => import("@/views/Content/Settings"),
  beforeEnter: (to, from, next) => {
    const options = {
      role: "user.auth",
      route: { to, from, next },
      callback: false,
      redirectText: "У вас нет доступа к разделу с настройками!",
    };
    validate_role(options);
  },
};
