var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "contematrix", attrs: { id: "app" } },
    [_c("router-view"), _c("portal-target", { attrs: { name: "popup" } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }