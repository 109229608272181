import { ProjectListGet } from "@/axios/project/project-list.get";

const state = () => ({
  loaded: false,
  list: [],
  platform: {},
});

const actions = {
  async fetch({ commit }) {
    const { data } = await ProjectListGet();
    if (data && data["result"] && data["result"]["projects"]) {
      commit("Loaded", true);
      commit("List", data["result"]["projects"]);
      commit("Platform", data["result"]["projects"][0]);
    }
  },
};

const mutations = {
  setCurrentPlatform(state, platform) {
    state.platform = platform;
  },
  Platform(state, val) {
    state.platform = val;
  },
  List(state, val) {
    state.list = val;
  },
  Loaded(state, val) {
    state.loaded = val;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
