import { TypeListGet } from "@/axios/type/list.get";

const state = () => ({
  list: [],
});

const actions = {
  async fetch({ commit }) {
    const data = await TypeListGet();
    if (Array.isArray(data)) {
      commit("List", data);
    }
  },
};

const mutations = {
  List(state, v) {
    state.list = v;
  },
};

const getters = {
  formattedList(state) {
    return state.list.map((type) => {
      return { ...type, name: `${type.name}` };
    });
  },
};

export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};
