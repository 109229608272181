import { CategoryListGet } from "@/axios/category/category-list.get";
import { CategoryListPut } from "@/axios/category/category-list.put";

const state = () => ({
  loaded: false,
  b2b: {
    list: [],
    total: 0,
  },
  b2c: {
    list: [],
    total: 0,
  },
});
const mutations = {
  Loaded(state, status) {
    state.loaded = status;
  },
  b2b(state, { list, total }) {
    state.b2b.list = list;
    state.b2b.total = total;
  },
  b2c(state, { list, total }) {
    state.b2c.list = list;
    state.b2c.total = total;
  },
};

const actions = {
  async fetch({ commit }) {
    const { data } = await CategoryListGet();
    if (data && Array.isArray(data["result"])) {
      commit("Loaded", true);
      for (const project of data["result"]) {
        commit(project["project"], {
          list: project["items"],
          total: project["total"],
        });
      }
    }
  },
  async update({ dispatch }, data) {
    await CategoryListPut(data.platform, data.list);
    return dispatch("fetch");
  },
};

const getters = {
  categories: (state) => {
    return { ...state };
  },
};

export default {
  namespaced: true,
  getters,
  state,
  actions,
  mutations,
};
