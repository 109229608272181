import axios from "@/axios";

const state = () => ({
  draft: {
    changes_count: 0,
    status: {},
  },
  select_id: 0,
  readonly: false,
  history_list: [],
});

const mutations = {
  setDraft(state, draft) {
    state.draft = draft;
  },
  setSelectId(state, id) {
    state.select_id = id;
  },
  setHistoryList(state, list) {
    state.history_list = list;
  },
  setReadonly(state, status) {
    state.readonly = status;
  },
};

const actions = {
  // загружаем информацию о текущем черновике
  async fetch({ commit }) {
    const { data } = await axios("/drafts/current");
    commit("setDraft", data["result"]["draft"]);
  },
  async setHistoryList({ commit }) {
    const { data } = await axios("/drafts/history");
    commit("setHistoryList", data["result"]["items"]);
  },
  setReadonly({ commit }, { status }) {
    commit("setReadonly", status);
  },
  setSelectId({ commit }, { id }) {
    commit("setSelectId", id);
  },
};

const getters = {
  counter: (state) => state.draft.changes_count,
  draft: (state) => state.draft,
  selectId: (state) => state.select_id,
  history: (state) => state.history_list,
  readonly: (state) => state.readonly,
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
