import validate_role from "@/router/module/validate_role";
import get_title from "@/router/module/get_title";

export default {
  path: "/content/history",
  meta: {
    title: get_title("История"),
    forVisitors: true,
  },
  component: () => import("@/views/Content/History"),
  beforeEnter: (to, from, next) => {
    const options = {
      role: "changes.show-list",
      route: { to, from, next },
      callback: false,
      redirectText: "У вас нет доступа к разделу с историей!",
    };
    validate_role(options);
  },
};
